<template>
  <div>
    <div class="accordion" role="tablist">
      <b-card
        no-body
        class="mb-1"
        v-for="(repeater, repeaterIndex) in repeaters"
        :key="repeaterIndex"
      >
        <b-card-header header-tag="header" class="p-1" role="tab">
          <v-row
            v-b-toggle="
              `accordion-${repeaterIndex}-` + getRepeaterKey(repeater)
            "
            @click="handleSortRepeaters"
          >
            <v-col cols="12" sm="2" md="2">
              <h3>
                {{ getFirstCustomFieldValue(repeaters[repeaterIndex]) }}
              </h3>
            </v-col>
            <v-spacer></v-spacer>

            <v-col cols="12 text-right" sm="2" md="2">
              <v-btn
                class="mr-2"
                text
                dark
                x-small
                color="pink"
                @click.stop="repeaterMove('down', repeaterIndex)"
                v-if="canRepeaterMove('down', repeaterIndex)"
              >
                <v-icon dark> mdi-arrow-down-bold</v-icon>
              </v-btn>
              <v-btn
                class="mx-2"
                text
                dark
                x-small
                color="pink"
                @click.stop="repeaterMove('up', repeaterIndex)"
                v-if="canRepeaterMove('up', repeaterIndex)"
              >
                <v-icon dark> mdi-arrow-up-bold</v-icon>
              </v-btn>
              <v-btn
                class="mx-2"
                text
                dark
                x-small
                color="pink"
                @click.stop="handleDeleteRepeater(repeaterIndex)"
              >
                <v-icon dark> mdi-delete</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </b-card-header>
        <b-collapse
          :id="`accordion-${repeaterIndex}-` + getRepeaterKey(repeater)"
          accordion="my-accordion"
          role="tabpanel"
        >
          <b-card-body>
            <b-card-text>
              <v-col
                v-for="(field, index) in repeaterPropData.repeaterConfig"
                :key="index"
                :class="getClass(field)"
              >
                <RepeaterCustomFieldComponent
                  v-if="repeaters && field.customFieldName != 'divider'"
                  :customFieldName="field.customFieldName"
                  :config="field.config"
                  :repeaterIndex="repeaterIndex"
                  :value="
                    repeaters[repeaterIndex][field.customFieldName] ||
                    field.config.defaultValue ||
                    ''
                  "
                  @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
                >
                </RepeaterCustomFieldComponent>
                <v-divider
                  v-if="field.customFieldName == 'divider'"
                ></v-divider>
                <h3 v-if="field.customFieldName == 'divider'">
                  {{ $t(field.config.label) }}
                </h3>
              </v-col>
            </b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>

    <v-row>
      <v-col>
        <v-btn
          color="primary"
          class="ma-2 white--text"
          @click="addEmptyRepeater"
          :disabled="canAddFile"
        >
          {{ $t(repeaterPropData.repeaterAddButtonTitle) }}
          <v-icon right dark> mdi-plus-circle </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <DeleteModalDialog
      :dialogDelete="dialogDelete"
      @closeDelete="closeDelete"
      @deleteItemConfirm="handleDeleteItemConfirm"
    >
    </DeleteModalDialog>
  </div>
</template>

<script>
import RepeaterCustomFieldComponent from "@/view/components/RepeaterCustomFieldComponent";
import DeleteModalDialog from "@/view/components/DeleteModalDialog";

export default {
  name: "RepeaterComponent",
  props: ["repeaterName", "repeaterPropData", "repeaterFieldValue"],
  components: {
    RepeaterCustomFieldComponent,
    DeleteModalDialog,
  },
  data() {
    return {
      showAll: false,
      repeaters: [],
      repeaterDeleteIndex: null,
      dialogDelete: false,
    };
  },

  watch: {
    repeaterFieldValue: {
      deep: true,
      handler(value) {
        this.repeaters = value;
        this.$emit("handleChangeValue", this.repeaterName, value);
      },
    },
  },

  methods: {
    getClass(field) {
      if (field.config && field.config.type == "media_selector") {
        return "col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12";
      }
      if (!field.config || !field.config.initialColumnClass)
        return "col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12";
      return field.config.initialColumnClass;
    },
    addEmptyRepeater() {
      let item = {};
      this.repeaterPropData.repeaterConfig.forEach((element) => {
        item[element.customFieldName] =
          element.type == "repeater" ||
          element.type == "external_references" ||
          element.type == "media_selector"
            ? []
            : null;
      });
      this.repeaters.push(item);

      this.$emit(
        "handleChangeRepeaterValue",
        this.repeaterName,
        this.repeaters
      );
    },

    handleChangeCustomFieldValue(customField, newValue, repeaterIndex) {
      this.repeaters[repeaterIndex][customField] = newValue;

      this.$emit(
        "handleChangeRepeaterValue",
        this.repeaterName,
        this.repeaters
      );
    },

    handleSortRepeaters() {
      let repeaters = [...this.repeaters];
      if (
        this.repeaterPropData &&
        this.repeaterPropData.repeaterOrderProperties &&
        this.repeaterPropData.repeaterOrderProperties.fieldName
      ) {
        if (this.repeaterPropData.repeaterOrderProperties.orderBy == "asc") {
          repeaters.sort((a, b) => {
            if (!a || !b) return 0;
            return a[
              this.repeaterPropData.repeaterOrderProperties.fieldName
            ].localeCompare(
              b[this.repeaterPropData.repeaterOrderProperties.fieldName]
            );
          });

          this.repeaters = repeaters;
        } else {
          repeaters.sort((a, b) => {
            if (!a || !b) return 0;
            return (
              -1 *
              a[
                this.repeaterPropData.repeaterOrderProperties.fieldName
              ].localeCompare(
                b[this.repeaterPropData.repeaterOrderProperties.fieldName]
              )
            );
          });
        }
      }

      this.$emit("handleChangeRepeaterValue", this.repeaterName, repeaters);
    },

    handleDeleteRepeater(repeaterDeleteIndex) {
      this.repeaterDeleteIndex = repeaterDeleteIndex;
      this.dialogDelete = true;
    },

    handleDeleteItemConfirm() {
      this.repeaters.splice(this.repeaterDeleteIndex, 1);

      this.dialogDelete = false;
      this.repeaterDeleteIndex = null;
      this.$emit(
        "handleChangeRepeaterValue",
        this.repeaterName,
        this.repeaters
      );
    },

    closeDelete() {
      this.repeaterDeleteIndex = null;
      this.dialogDelete = false;
    },

    canRepeaterMove(direction, repeaterIndex) {
      if (
        this.repeaterPropData &&
        this.repeaterPropData.repeaterOrderProperties &&
        this.repeaterPropData.repeaterOrderProperties.fieldName
      )
        return false;
      if (direction == "down") {
        if (this.repeaters.length > repeaterIndex + 1) {
          return true;
        } else {
          return false;
        }
      }

      if (repeaterIndex == 0) {
        return false;
      } else {
        return true;
      }
    },

    repeaterMove(direction, repeaterIndex) {
      let repeaters = [...this.repeaters];
      if (direction == "up") {
        repeaters[repeaterIndex - 1] = this.repeaters[repeaterIndex];
        repeaters[repeaterIndex] = this.repeaters[repeaterIndex - 1];
      } else {
        repeaters[repeaterIndex + 1] = this.repeaters[repeaterIndex];
        repeaters[repeaterIndex] = this.repeaters[repeaterIndex + 1];
      }
      this.repeaters = repeaters;
      this.$emit(
        "handleChangeRepeaterValue",
        this.repeaterName,
        this.repeaters
      );
    },

    getFirstCustomFieldValue(cFields) {
      if (
        this.repeaterPropData["repeaterTitleFieldName"] &&
        cFields[this.repeaterPropData["repeaterTitleFieldName"]]
      ) {
        return cFields[this.repeaterPropData["repeaterTitleFieldName"]];
      }

      return "";
    },

    getRepeaterKey(repeater) {
      let keys = Object.keys(repeater);

      if (keys && keys.length > 0 && repeater[keys[0]]) {
        return repeater[keys[0]].replaceAll(" ", "");
      }

      return "";
    },
  },
  mounted() {},
};
</script>
